.notification-panel {
  padding: 2px;
  background: white;
  border-radius: 8px;
  width: 300px;
  text-align-last: left;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow: hidden;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.notification-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.mark-read-btn {
  background: none;
  border: none;
  color: #1F4A31;
  cursor: pointer;
  font-size: 14px;
}

.notification-list {
  max-height: 300px; 
  overflow-y: auto; 
}

.notification-list::-webkit-scrollbar {
  width: 6px;
}

.notification-list::-webkit-scrollbar-thumb {
  background-color: #ccc; 
  border-radius: 3px;
}

.notification-list::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.notification-item {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification-item.unread {
  background: rgba(31, 74, 49, 0.2);
  font-weight: bold;
}

.notification-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.notification-content p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.notification-content .notification-user {
  font-weight: bold;
}

.notification-content .notification-highlight {
  font-weight: bold;
  color: #1562b5;
}

.notification-time {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.notification-panel-container {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  margin-top: 10px;
  background-color: white;
  width: 320px; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  z-index: 9999;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 5px;
  padding: 10px;
}
