.line-container {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
}

.line-container::before,
.line-container::after {
    content: '';
    flex: 1;
    border-top: 1px solid #A0A0A0;
    margin: 0 10px;
}

.line-container span {
    padding: 0 10px;
    font-weight: 500;
}
