.password-policy-container {
  position: absolute;
  background-color: #1F4A31; /* Dark green background */
  color: white; /* White text for contrast */
  border-radius: 8px;
  padding: 1rem;
  top: -65px;
  right: -265px; /* Adjust based on your layout */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: auto;
  max-width: 250px;
  display: inline-block; /* Allows it to resize with content */
  transition: all 0.3s ease; /* Smooth resizing transition */
}

.password-policy-container h4 {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.password-policy-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.password-policy-list li {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  margin: 0.3rem 0;
  color: white;
  padding-left: 1.5rem;
  position: relative;
}

.password-policy-list li.valid {
  color: lightgreen;
}

.checkmark {
  font-size: 1rem;
  color: lightgreen;
  position: absolute;
  left: 0; 
  width: 1.5rem;
  display: inline-block;
  text-align: center;
}

.password-policy-container::before {
  content: "";
  position: absolute;
  left: -16px;
  top: 90px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent #1F4A31 transparent transparent;
}
