/* src/styles/Explore.css */

.Explore {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  color: #333;
}

.header {
  flex-shrink: 0;
  background-color: #f8f8f8;
  padding: 10px;
  text-align: center;

}

.search-bar {
  flex-shrink: 0;
  padding: 20px;
  background-color: #ffffff;
}

/* Container for the Filters Button on Small Screens */
.hamburger-button-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}

/* Container for the View Toggle Button on Small Screens */
.view-toggle-button-container {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #ffffff;
}

/* Main Content Layout */
.main-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 20px;
  gap: 20px;
  align-items: stretch; /* Ensures children stretch to fill the height */
}

/* Filters Section */
.filters {
  flex: 1;
  max-width: 200px;
}

/* Business List Section */
.business-list {
  flex: 2;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Map Section */
.map {
  flex: 1;
  max-width: 80%;
  min-width: 300px; /* Added 'px' */
  /* Removed height: 100%; */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex; /* Allows the child to fill the container */
}

.map > div { /* Ensures the Map component fills the .map container */
  flex: 1;
}

/* Drawer Content Styling */
.drawer-content {
  width: 250px; /* Adjust the width as needed */
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensures full height */
  overflow-y: auto; /* Adds vertical scroll if content overflows */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .filters {
    max-width: 150px;
  }

  .business-list {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    padding: 10px;
  }

  .filters,
  .business-list,
  .map {
    max-width: 100%;
    margin: 0;
  }

  .map {
    height: 300px; /* Maintains a set height on smaller screens */
    margin-top: 20px;
  }

  /* Adjust hamburger button container padding */
  .hamburger-button-container {
    padding: 10px 10px;
  }

  /* Adjust view toggle button container padding */
  .view-toggle-button-container {
    padding: 10px 10px;
  }
}

/* Optional: Style the Buttons for Consistency */
.hamburger-button-container .MuiButton-root,
.view-toggle-button-container .MuiButton-root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
