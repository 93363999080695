.navDiv{
    height: 6rem;
    position: fixed;
    margin-top: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 1000;
}

.navDiv img{
    float: left;
    max-width: 100%;
    height: 4rem;
}